import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import appSettings from '../../configs/AppConfig';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import Pagination from '../../common/Pagination/pagination';
import { handleApiResponse } from '../../apis/apiUtils';

const Manage = () => {
    const [file, setFile] = useState(null);
    const [errorsInfo, setErrorsInfo] = useState({});
    const [voucherList, setVoucherList] = useState([]);
    const { loading, user } = useSelector((state) => state.common);
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: "all" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf('year').subtract(1, 'year'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        if (filter.search_string.length > 2 || filter.search_string == "") {
            getVoucherList(1, filter.status, startDate, endDate);
        }
    }, [filter.search_string, filter.status]);
    const handleDateChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        getVoucherList(1, filter.status, new Date(startDate), new Date(endDate));
    };

    /** Voucher List Method **/
    const getVoucherList = async (page, status, startDate, endDate) => {
        try {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Voucher', 'List'] }));
            const res = await sendRequest('/voucher/list', 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, startDate: startDate, endDate: endDate, status: status });
            dispatch(callCommonAction({ loading: false }));
            if (res.data.type === "success") {
                setVoucherList(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /** Render Voucher Table Body **/
    const renderTableBody = () => {
        return voucherList.map((voucher) => (
            <tr key={Math.random()}>
                <td>{voucher.code}</td>
                <td>{`$${voucher.amount}`}</td>
                <td>{voucher.status == 1 ? 'Claimed' : 'Unclaimed'}</td>
                <td className="text-end">{moment(voucher.expiry).format('MM-DD-YYYY')}</td>
            </tr>
        ));
    };

    /*** Handle Change File Of Voucher In XLS ***/
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB limit
        const validTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        if (selectedFile) {
            if (selectedFile.size > maxSize) {
                setErrorsInfo({ voucher_file: 'File size exceeds the limit of 5MB' });
            } else if (!validTypes.includes(selectedFile.type)) {
                setErrorsInfo({ voucher_file: 'Invalid file type. Please upload an XLS or XLSX file.' });
            } else {
                setFile(selectedFile);
                setErrorsInfo({ voucher_file: '' });
            }
        } else {
            setFile(null);
            setErrorsInfo({ voucher_file: 'Please select a file first' });
        }
    };

    /** Upload Voucher File **/
    const handleFileUpload = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();
            formData.append('file', file);
            if (Object.keys(errorsInfo).length === 0 || (errorsInfo.voucher_file == '')) {
                if (!file) {
                    setErrorsInfo({ voucher_file: 'Please select a file first' });
                    return false
                }
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest('/voucher/save', 'POST', formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getVoucherList(1);
                }
            } else {
                console.error('Error saving', errorsInfo)
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };




    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>leadBoost | Vouchers </title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            {
                user && user.role === 1 && (<div className="post d-flex flex-column-fluid" id="kt_post">
                    {/**begin::Container**/}
                    <div id="kt_content_container" className="container-xxl">
                        {/**begin::Card**/}
                        <div className="card">
                            {/**begin::Card header**/}
                            <div className="card-header">
                                {/**begin::Card title**/}
                                <div className="card-title">
                                    <h4>Voucher Manage</h4>
                                </div>
                            </div>
                            {/**end::Card header**/}
                            {/**begin::Card body**/}
                            <div className="card-body">
                                <form onSubmit={handleFileUpload}>
                                    <div className="row row-cols-1 row-cols-sm-3">
                                        <div className="col">
                                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                                <label className="fs-6 fw-semibold form-label mt-3">
                                                    <span className="required">Upload File</span>
                                                </label>
                                                <input type="file" onChange={handleFileChange} accept={".xls"} className={`form-control  ${errorsInfo.voucher_file ? 'is-invalid' : ''}`} />
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">{errorsInfo.voucher_file}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-items-start d-flex flex-column">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                &nbsp;
                                            </label>
                                            <button type="submit" className='btn btn-primary' disabled={loading}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/**end::Card body**/}
                        </div>
                        {/**end::Card**/}
                    </div>
                </div>)
            }

            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title gap-2">
                                {/**begin::Search**/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search Voucher" />
                                </div>
                                {/**end::Search**/}
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex">
                                    <div className="align-items-center d-flex flex-md-nowrap flex-wrap">
                                        <div className="align-items-center d-flex mb-3 mb-md-0 me-0 me-md-2 w-100">
                                            <label className="form-label fw-semibold mb-0 me-2">
                                                Status:
                                            </label>
                                            <div className="w-100">
                                                <select
                                                    className="form-select form-select-solid fw-bold"
                                                    value={filter.status}
                                                    onChange={(e) => {
                                                        setFilter({ ...filter, status: e.target.value });
                                                    }}
                                                >
                                                    <option value="all">All</option>
                                                    <option value="1">Claimed</option>
                                                    <option value="0">Unclaimed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/**begin::Daterangepicker(defined in src/js/layout/app.js)**/}
                                    <div className="btn btn-sm btn-light d-flex align-items-center px-4">
                                        {/**begin::Display range**/}
                                        <div className="text-gray-600 w-100 fw-bold fs-14 custom-date-pick">
                                            <DateRangePicker
                                                startDate={startDate}
                                                startDateId="start_date"
                                                endDate={endDate}
                                                endDateId="end_date"
                                                onDatesChange={handleDateChange}
                                                focusedInput={focusedInput}
                                                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                isOutsideRange={() => false} // Allow all dates
                                                displayFormat="MM/DD/YYYY"
                                            />
                                        </div>
                                        {/**end::Display range**/}

                                    </div>

                                    {/**end::Daterangepicker**/}
                                </div>

                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body py-0 table-frame">
                            {/**begin::Table wrapper**/}
                            <div className="table-responsive">
                                {/**begin::Table**/}
                                <table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5" id="kt_table_customers_logs">
                                    {/**begin::Table body**/}
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px">Code</th>
                                            <th className="min-w-125px">Amount</th>
                                            <th className="min-w-125px">Status</th>
                                            <th className="min-w-125px text-end">Expiry</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {voucherList.length > 0 ? renderTableBody() : <tr>
                                            <td colSpan={4} className='text-center'>No result found!</td>
                                        </tr>}
                                    </tbody>
                                    {/**end::Table body**/}
                                </table>
                                {/**end::Table**/}

                            </div>
                            <div className='pag mb-4'>
                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                    pageSize={paginationData.perpage} onPageChange={page => getVoucherList(page, filter.status, startDate, endDate)}
                                />
                            </div>
                            {/**end::Table wrapper**/}
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
        </div>
    );
};

export default Manage;